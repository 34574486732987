import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import { render, RenderOptions, RenderResult } from '@testing-library/react'
import React, { PropsWithChildren, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store, { AppStore, reducer, RootState } from '../../store'

export type TProps = Record<string, unknown>

export type TCreateSetupParams = {
  component: () => JSX.Element
  props?: TProps
}

export type TRenderResult = RenderResult & {
  props: TProps
}

export type TTestSetupFn = (params?: React.ReactElement) => TRenderResult
export type TCreateTestSetupFn = (params: TCreateSetupParams) => TTestSetupFn

export const createTestSetup = (params: TCreateSetupParams): TTestSetupFn => {
  const { component: Component, props: initialProps = {} } = params

  return (ui?: React.ReactElement): TRenderResult => {
    const uiProps = ui?.props ?? {}
    const combinedProps = { ...initialProps, ...uiProps }

    const wrappedComponent = (props: TProps) => (
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <Component {...props} />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    )

    const wrapper = render(wrappedComponent(combinedProps))

    const rerender = (ui: React.ReactElement) => {
      const { props } = ui
      const newProps = { ...combinedProps, ...props }

      wrapper.rerender(wrappedComponent(newProps))
    }

    return {
      ...wrapper,
      rerender,
      props: ui?.props,
    }
  }
}

interface ExtendedRenderOptions extends Omit<RenderOptions, 'queries'> {
  preloadedState?: PreloadedState<RootState>
  store?: AppStore
}

export const renderWithProviders = (
  ui: ReactElement,
  {
    store = configureStore({ reducer: reducer }),
    ...renderOptions
  }: ExtendedRenderOptions = {}
) => {
  const Wrapper = ({ children }: PropsWithChildren): JSX.Element => (
    <Provider store={store}>{children}</Provider>
  )

  // Return an object with the store and all of RTL's query functions
  return { store, ...render(ui, { wrapper: Wrapper, ...renderOptions }) }
}
