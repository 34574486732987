import { FC } from 'react'
import dayjs from 'dayjs'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'

interface StreamTimerProps {
  channelId: number
}

const TIME_FORMAT = 'HH:mm:ss DD/MM/YYYY'

export const StreamTimer: FC<StreamTimerProps> = ({ channelId }) => {
  const currentTime = useTypedSelector(
    ({ channel }) => channel.timers[channelId]?.currentTime
  )

  return (
    <span data-testid="stream-timer">
      {dayjs(currentTime).format(TIME_FORMAT)}
    </span>
  )
}
