import moment from 'moment'
import React, { FC } from 'react'
import { SocketData } from '..'
import '../../../assets/styles/components/rightsidebar.css'
import './alarm.css'

export interface IAlarmsTreeNode {
  Name: string
  Duration: {
    Min: string
    Sec: string
  }
  Id: string
  Type: string
}

export interface IAlarmsTreeProps {
  data: SocketData
  onRemove: (key: string) => void
}

type StateArgs = {
  [key: number]: string
}

const STATE: StateArgs = {
  0: '',
  1: 'bg-blue',
  2: 'bg-red',
}

const AlarmCard: FC<IAlarmsTreeProps> = ({ data, onRemove }) => {
  return (
    <div
      className={`alarm_item mb-1.5 mb-1.5 flex h-8 justify-between text-xs text-2xs
      ${STATE[data.State]}`}
      data-testid="alarm-container"
    >
      <div className="ml-2 truncate">{data.Code}</div>
      <div className="ml-2">{moment(data.created_at).format('lll')}</div>
      <div className="mr-2 flex items-center">
        <input
          id={data.Guid}
          type="checkbox"
          className="invisible h-2 w-2 rounded-lg"
        />
        <label
          className="label-alarm text-gray-90 mr-0 block font-medium"
          onClick={() => onRemove(data.Guid)}
        />
      </div>
    </div>
  )
}

export default AlarmCard
