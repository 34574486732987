import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { AUTH_URL } from 'constants/endpoints'
import AuthService from '../services/auth'
import { COOKIE_ITEM, CookieService } from '../services/cookies'
import { useDispatch } from 'store'
import { setUser } from 'store/slices/auth'

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = CookieService.get(COOKIE_ITEM.ACCESS_TOKEN)
    if (!token) {
      window.location.href = `${AUTH_URL}/login?redirectTo=${window.location.href}`
      return
    }

    setLoading(true)
    AuthService.getCurrentUser(token)
      .then((user) => {
        dispatch(setUser(user))
        setLoading(false)
      })
      .catch(() => {
        window.location.href = `${AUTH_URL}/login?redirectTo=${window.location.href}`
      })
  }, [])

  if (loading) {
    return (
      <div className="flex-center h-screen w-screen">
        <CircularProgress />
      </div>
    )
  }

  return <>{children}</>
}
