import Swal, { SweetAlertInput, SweetAlertOptions } from 'sweetalert2'

export const showInputAlert = (
  title: string,
  input: SweetAlertInput = 'text',
  options?: SweetAlertOptions
) => {
  return Swal.fire({
    title,
    input,
    reverseButtons: false,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    inputAutoTrim: true,
    ...options,
    customClass: {
      container: 'spotter-swal2',
    },
  })
}
