import { COOKIE_ITEM, CookieService } from 'common-lib/services/cookies'
import { WatchEventType, watch } from 'common-lib/services/watch'
interface ReceiverOptions {
  restartPause?: number
}

const defaultRestartPause = 2000

const uuid = () => {
  const u = URL.createObjectURL(new Blob(['']))
  URL.revokeObjectURL(u)
  return u.split('/').slice(-1)[0]
}

// TODO: we still dont have the api to get the peer connection info, later remove this object and the config to add in other spot
const namespace = 'test-organization'
const serverURL = 'https://api.dev.mirasys.com'
const systemAPI = serverURL + '/apis/system.mirasys.com/v1alpha1'
const defaultPeerConnectionsAPI =
  systemAPI + '/namespaces/' + namespace + '/peerconnections'

export class Receiver {
  private terminated: boolean
  private pc: null | RTCPeerConnection
  private restartTimeout: null | number
  private readonly startTime: number | undefined
  private readonly streamRef: string
  private videoRef: HTMLVideoElement
  private handleConnectionStateChanged?: (
    state?: RTCIceConnectionState,
    extra?: Record<'timestamp', unknown>
  ) => void

  public timestamp: number | null

  private opt?: ReceiverOptions

  constructor(
    videoRef: HTMLVideoElement,
    streamRef: string,
    startTime?: number | undefined,
    opt?: ReceiverOptions
  ) {
    this.terminated = false
    this.pc = null
    this.restartTimeout = null
    this.timestamp = null
    this.streamRef = streamRef
    this.startTime = startTime
    this.videoRef = videoRef
    this.opt = opt
    this.start()
  }

  private start() {
    if (!this.videoRef) {
      return
    }

    this.onIceServers()
  }

  public onConnectionStateChanged(
    cb: (
      state?: RTCIceConnectionState,
      extra?: Record<'timestamp', unknown>
    ) => void
  ) {
    this.handleConnectionStateChanged = cb
  }

  private onIceServers() {
    if (this.pc != null) return

    this.pc = new RTCPeerConnection({
      iceServers: [{ urls: ['stun:fr-turn1.xirsys.com'] }], // TODO: this must be a parameter
      iceCandidatePoolSize: 4,
    })

    const direction = 'recvonly'
    this.pc.addTransceiver('video', { direction })
    this.pc.addTransceiver('audio', { direction })
    this.pc.setLocalDescription()

    this.pc.oniceconnectionstatechange = () => {
      if (this.pc === null) {
        return
      }

      if (this.restartTimeout !== null) {
        return
      }

      switch (this.pc.iceConnectionState) {
        case 'disconnected':
          this.scheduleRestart()
          break
      }

      this.handleConnectionStateChanged?.(this.pc.iceConnectionState, {
        timestamp: this.timestamp,
      })
    }

    this.pc.onicegatheringstatechange = (evt) => {
      console.log('onicegatheringstatechange', evt)
      if (this.pc == null) return
      // @ts-ignore
      if (evt.target?.iceGatheringState == 'complete') {
        this.pc.createOffer().then((desc) => {
          if (this.pc === null) {
            return
          }
          this.pc.setLocalDescription(desc)
          this.connectWebRTC(desc)
        })
      }
    }

    this.pc.ontrack = (evt) => {
      console.log('new track:', evt.track.kind)
      if (evt.track.kind == 'video') {
        console.log('bind to object:', evt.streams[0])
        this.videoRef.srcObject = evt.streams[0]
      }
    }
  }

  private connectWebRTC(desc: RTCSessionDescriptionInit) {
    const objPeerConnection = {
      kind: 'PeerConnection',
      apiVersion: 'system.mirasys.com/v1alpha1',
      metadata: {
        name: uuid(),
        namespace: namespace,
      },
      spec: {
        user: {
          uuid: '',
          sid: '',
          jti: '',
        },
        gatekeeperRef: '0bf46d3b-8425-472a-8300-56f0a39d1058',
        offerSDP: '',
        tracks: [
          {
            id: uuid(),
            streamRef: this.streamRef,
            streamKind: 'VideoStream',
            ...(this.startTime // if start is defined, start stream from there (aka playback)
              ? {
                  startTime:
                    new Date(this.startTime + 3 * 60 * 60 * 1000)
                      .toISOString()
                      .split('.')[0] + 'Z',
                }
              : {}),
          },
        ],
      },
    }
    if (desc.sdp) {
      objPeerConnection.spec.offerSDP = desc.sdp
    }
    const token = CookieService.get(COOKIE_ITEM.ID_TOKEN) || ''
    fetch(defaultPeerConnectionsAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(objPeerConnection),
    })
      .then((res) => {
        console.log('got res', res)
        if (res.status !== 201) {
          throw new Error('bad status code')
        }
        return res.json()
      })
      .then((obj) => {
        watch(
          defaultPeerConnectionsAPI,
          token,
          obj.metadata.resourceVersion,
          obj.metadata.name,
          (_type: WatchEventType, obj: { status: { answerSDP: string } }) => {
            if (this.pc == null) return

            if (
              obj.status.answerSDP &&
              !this.pc.currentRemoteDescription &&
              this.restartTimeout === null
            ) {
              console.log('setting remote description', obj.status.answerSDP)
              this.pc.setRemoteDescription(
                new RTCSessionDescription({
                  sdp: obj.status.answerSDP,
                  type: 'answer',
                })
              )
            }
          }
        )
      })
  }

  private scheduleRestart() {
    if (this.terminated) {
      return
    }

    if (this.pc !== null) {
      this.pc.close()
      this.pc = null
    }

    this.restartTimeout = window.setTimeout(() => {
      this.restartTimeout = null
      this.start()
    }, this.opt?.restartPause || defaultRestartPause)
  }

  public terminate() {
    this.terminated = true
    this.pc?.close()
  }
}
