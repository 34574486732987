export const VAULT_HOST_URI =
  process.env.REACT_APP_VAULT || 'https://vault.webtest.luxaslabs.com:8200'
export const LOGIN_URI = '/v1/auth/userpass/login/'
export const LOGOUT_URI = '/api-proxy/spotterwebinternalapi/login/logout'
export const PUBLIC_KEY_URI =
  '/api-proxy/spotterwebinternalapi/encryption/GetPublicKey'
export const STREAM_OPEN_URI = '/api-proxy/spotterwebinternalapi/streaming/open'
export const WS_STREAM_URI =
  'wss://127.0.0.1:8080/api-proxy/spotterwebinternalapi/'
export const STREAM_CLOSE_URI =
  '/api-proxy/spotterwebinternalapi/streaming/close'
export const PLAYBACK_GROUP_URI =
  '/api-proxy/spotterwebinternalapi/playbackstreaming/group'
export const PLAYBACK_GROUP_PAUSE_URI = `${PLAYBACK_GROUP_URI}/pause`
export const PLAYBACK_GROUP_SET_TIME_URI = `${PLAYBACK_GROUP_URI}/settime`
export const PLAYBACK_GROUP_STEP_FORWARD_URI = `${PLAYBACK_GROUP_URI}/stepforward`
export const PLAYBACK_GROUP_PLAY_FORWARD_URI = `${PLAYBACK_GROUP_URI}/playforward`
export const PLAYBACK_GROUP_STEP_BACKWARD_URI = `${PLAYBACK_GROUP_URI}/stepbackward`
export const PLAYBACK_GROUP_PLAY_BACKWARD_URI = `${PLAYBACK_GROUP_URI}/playbackward`
export const PLAYBACK_GROUP_ADD_OR_DELETE_URI = `${PLAYBACK_GROUP_URI}/stream`
export const PLAYBACK_GROUP_TIME_AND_PLAY_FORWARD_URI = `${PLAYBACK_GROUP_URI}/settimeandplayforward`
export const PLAYBACK_GROUP_TIME_AND_PLAY_BACKWARD_URI = `${PLAYBACK_GROUP_URI}/settimeandplaybackward`
