import { FC } from 'react'
import { BroadcastIcon, PauseGreenIcon, StepBackIcon } from 'assets/icons'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { StreamStatus } from 'store/slices/channel'

interface StreamNameProps {
  channelId: number
  name?: string
}

const streamStatusIcons = {
  [StreamStatus.Playback]: <StepBackIcon className="h-4 fill-secondary" />,
  [StreamStatus.Live]: <BroadcastIcon className="h-5" />,
  [StreamStatus.Paused]: <PauseGreenIcon className="h-5" />,
}

export const StreamName: FC<StreamNameProps> = ({ channelId, name = '' }) => {
  const status = useTypedSelector(
    ({ channel }) => channel.timers[channelId]?.status ?? StreamStatus.Live
  )

  if (!channelId) return null

  const mode = (
    <div className="flex items-center gap-1" data-testid="stream-mode">
      {streamStatusIcons[status]}
      <p className="text-capitalize">{StreamStatus[status]}</p>
    </div>
  )

  return (
    <div
      className="flex gap-3 rounded bg-black bg-opacity-50 px-2"
      data-testid="stream-name"
    >
      {[name, mode].map((item, index) => (
        <div className="flex items-center gap-3" key={index}>
          <div>{item}</div>
          {index !== name.length - 1 && (
            <div className="h-full w-[1px] bg-white bg-opacity-50" />
          )}
        </div>
      ))}
    </div>
  )
}
