import { useEffect } from 'react'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import NewTabButton from './NewTabButton'
import Tab from './Tab'

const Bar = () => {
  const tabState = useTypedSelector((state) => state.tab)

  useEffect(() => {
    localStorage.setItem('SAVED_TABS', JSON.stringify(tabState.tabs))
  }, [tabState.tabs])

  return (
    <div className="flex justify-between">
      <div className="flex gap-1">
        {tabState.tabs.map((tab, index) => (
          <Tab
            key={index}
            idx={index}
            tab={tab}
            activeTabIdx={tabState.activeTabIdx}
            first={index === 0}
            last={index === tabState.tabs.length - 1}
          />
        ))}
        <NewTabButton />
      </div>
    </div>
  )
}

export default Bar
