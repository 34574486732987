import React, { useRef, useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import profile from 'assets/icons/profile.svg'
import { LogoutButton } from 'common-lib/components'
import { useSelector } from 'store'
import { getProfile } from 'store/selectors'

const User = () => {
  const user = useSelector(getProfile)
  const ref = useRef<HTMLImageElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
  const open = Boolean(anchorEl)

  const onClose = () => {
    setAnchorEl(undefined)
  }
  return (
    <>
      <div className="flex flex-row justify-center pt-1.5">
        <p className="mr-3 pt-0.5 text-sm">{user.email}</p>
        <button
          type="button"
          onClick={(e) => setAnchorEl(e.currentTarget as any)}
          data-testid="click-show"
        >
          <img
            src={profile}
            ref={ref}
            alt="profile_image"
            className="mt-0.5 h-6 w-6 cursor-pointer"
            data-testid="img-profile"
          />
        </button>
      </div>

      <Menu
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: 'w-40',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onClose}
      >
        <MenuItem
          className="hover:bg-secondary-800 bg-transparent"
          onClick={onClose}
        >
          <PersonIcon className="text-xl" />
          <span className="ml-3">Profile</span>
        </MenuItem>
        <LogoutButton>
          <MenuItem className="hover:bg-secondary-800" onClick={onClose}>
            <LogoutIcon className="text-xl" />
            <span className="ml-3">Log out</span>
          </MenuItem>
        </LogoutButton>
      </Menu>
    </>
  )
}

export default User
