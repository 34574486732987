import { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { SortAlarms } from 'utils/helpers/sort'
import AlarmsCard from './AlarmsCard'
import User from './User'
import './rightsidebar.css'

const SOCKET_URL = process.env.REACT_APP_OLD_SOCKET_URL

export type SocketData = {
  Code: string
  Index: number
  JsonData: { Type: string }
  Guid: string
  State: number
  Format: string
  Content: string
  created_at: Date
}

const RightSidebar = () => {
  const [isConnected, setIsConnected] = useState(false)
  const [socketData, setSocketData] = useState<SocketData[]>(() => {
    const storedValues = localStorage.getItem('lists') || ''

    return storedValues ? JSON.parse(storedValues) : []
  })

  useEffect(() => {
    const socket = io(`${SOCKET_URL}`)

    socket.on('connect', () => {
      setIsConnected(socket.connected)
    })

    socket.on('message', (data: SocketData) => {
      setSocketData((prev) => [...prev, { ...data, created_at: new Date() }])
    })

    return () => {
      socket.disconnect()
    }
  }, [isConnected])

  useEffect(() => {
    if (socketData.length > 0) {
      localStorage.setItem('lists', JSON.stringify(socketData))
    }
  }, [socketData.length])

  const onRemove = (key: string) => {
    if (key) {
      const filterSocket = socketData.filter((item) => item.Guid !== key)
      setSocketData(filterSocket)
    }
  }

  return (
    <div
      className="no-scrollbar relative h-full overflow-y-scroll text-white"
      data-testid="right-side-container"
    >
      <div className="sticky top-0 right-0 z-20 w-53 bg-black">
        <div className="flex justify-center">
          <User />
        </div>
        <div className="flex w-full justify-center rounded-tl-lg bg-primary-100 pb-2.5 pt-2.5 text-sm font-bold">
          <p>Alarms</p>
        </div>
      </div>
      <div className="relative z-10 flex w-53 flex-1 rounded-bl bg-primary-100">
        <ul className="w-full">
          {socketData.sort(SortAlarms).map((content) => (
            <li key={content.Guid}>
              <AlarmsCard data={content} onRemove={onRemove} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default RightSidebar
