import PointerSvg from '../../../assets/img/pointer1.svg'

const Pointer = () => (
  <div className="drag" data-testid="pointer-tree-node">
    <img
      src={PointerSvg}
      style={{ height: 64, width: 100 }}
      alt="pointer"
      data-testid="img-pointer"
    />
  </div>
)

export { Pointer }
