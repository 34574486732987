import classNames from 'classnames'
import { useState } from 'react'
import CameraIcon from 'assets/icons/camera-white.svg'
import GreenCameraIcon from 'assets/icons/camera-green.svg'
import CaretDownIcon from 'assets/icons/caret-down.svg'
import CaretRightIcon from 'assets/icons/caret-right.svg'
import FolderIcon from 'assets/icons/folder.svg'
import { isUndefined } from 'utils/helpers'

export interface IProfileTreeNode {
  Name: string
  IsEnabled?: boolean
  Camera?: {
    Guid: string
    Name: string
    IsEnabled: boolean
    Channel: number
    Mode: 'Live' | 'Playback'
  }
  ProfileNodes?: IProfileTreeNode[]
}

export interface IProfileTreeProps {
  data: IProfileTreeNode
  level: number
  onClickNode(node: IProfileTreeNode): void
  channelIds: Array<number>
}

const ProfileTree = ({
  data,
  level,
  onClickNode,
  channelIds,
}: IProfileTreeProps) => {
  const [isOpened, setIsOpened] = useState(level < 2)
  const isDir = isUndefined(data.Camera)

  const channelOpened = channelIds?.includes(data.Camera?.Channel as number)

  const onClick = () => {
    if (isDir) {
      setIsOpened(!isOpened)
    } else {
      if (!channelOpened) {
        onClickNode(data)
      }
    }
  }

  return (
    <div>
      <div
        className="group mb-1 flex h-6 cursor-pointer items-center bg-primary pr-1 text-white outline-none"
        style={{ paddingLeft: `${0.25 + level * 0.75}rem` }}
        onClick={onClick}
        role="button"
        data-testid="profile-tree-node"
      >
        {isDir && (
          <img
            className="mr-1 w-4 brightness-0 invert group-hover:scale-150"
            src={isOpened ? CaretDownIcon : CaretRightIcon}
            alt=""
          />
        )}
        <span
          className={classNames(
            'w-0 flex-grow truncate',
            isDir ? 'text-xs font-semibold' : 'ml-3 text-2xs'
          )}
          title={data.Name}
        >
          {data.Name}
        </span>

        <div className="ml-auto flex h-full min-w-8 items-center justify-center">
          {level === 0 ? (
            <div
              className={classNames(
                'h-full w-2',
                data.IsEnabled ? 'bg-secondary' : 'bg-danger'
              )}
            />
          ) : isDir ? (
            <img className="w-5 brightness-0 invert" src={FolderIcon} alt="" />
          ) : channelOpened ? (
            <img
              className="w-5 cursor-pointer hover:scale-125"
              src={GreenCameraIcon}
              alt=""
            />
          ) : (
            <img
              className="w-4 cursor-pointer hover:scale-125"
              src={CameraIcon}
              alt=""
            />
          )}
        </div>
      </div>

      {isDir &&
        isOpened &&
        (data.ProfileNodes || []).map((child, i) => (
          <ProfileTree
            key={i}
            data={child}
            level={level + 1}
            onClickNode={onClickNode}
            channelIds={channelIds}
          />
        ))}
    </div>
  )
}

export default ProfileTree
