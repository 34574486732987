import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { auth, channel, tab } from './slices'

export const reducer = combineReducers({
  auth,
  tab,
  channel,
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store

export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector
export const useDispatch = () => useAppDispatch<AppDispatch>()
