import { CrossIcon } from 'assets/icons'
import { FC } from 'react'
import classNames from 'classnames'

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#607D8B',
  '#000000',
]

interface ColorPaletteProps {
  onSelect?: (color: string | null) => unknown
  className?: string
}

const ColorPalette: FC<ColorPaletteProps> = ({ onSelect, className }) => {
  const classes = classNames(
    'grid-rows-auto grid h-max w-max grid-cols-4 gap-1 bg-primary-100 p-1 shadow-lg',
    className
  )

  const handleSelect = (color: string | null) => () => {
    onSelect?.(color)
  }

  return (
    <div className={classes}>
      <span
        className="hover:drop-shadow-primary flex h-6 w-6 items-center justify-center bg-white hover:cursor-pointer"
        onClick={handleSelect(null)}
      >
        <CrossIcon className="h-full w-full fill-[#D32F2F]" />
      </span>
      {colors.map((color, index) => (
        <span
          className="hover:drop-shadow-primary h-6 w-6 hover:cursor-pointer"
          style={{ backgroundColor: color }}
          key={index}
          onClick={handleSelect(color)}
        />
      ))}
    </div>
  )
}

export default ColorPalette
