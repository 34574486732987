import { createSlice } from '@reduxjs/toolkit'
import { fetchUIState } from 'services'
import pullAt from 'lodash/pullAt'

export interface CommonTab {
  name: string
  type: string
  disabled?: boolean
  icon?: JSX.Element
  pinned?: boolean
  bookmarkColor?: string
  disableFlag?: boolean
}

export interface ViewTab extends CommonTab {
  view: {
    layout: {
      rows: number
      columns: number
    }
    streamSrc: Array<number>
  }
}

interface ToolTab extends CommonTab {
  tool: {
    toolType: string
    search: {
      searchType: string
      searchClass: string
      top: string
      bottom: string
      cameras: Array<number>
      timeStart: string
      timeEnd: string
    }
  }
}

export type TabType = CommonTab | ViewTab | ToolTab

// fetch the initital state of the tabs bar
export interface TabState {
  tabs: Array<TabType>
  activeTabIdx: number
  showDropdownMenuId: number
}

const tabs: Array<TabType> = fetchUIState().tabs

const initialState: TabState = {
  tabs,
  activeTabIdx: 0,
  showDropdownMenuId: 0,
}

const tab = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    switchTab: (state, action) => {
      state.activeTabIdx = action.payload
      return state
    },
    createNewTab: (state) => {
      const count = state.tabs.length
      state.tabs.push({
        name: 'New Tab ' + (count + 1),
        type: 'new',
        disableFlag: true,
      })
      state.activeTabIdx = count
    },
    closeTab: (state, action) => {
      const closedTabIdx = action.payload
      if (state.tabs.length === 1) {
        state.tabs = [
          {
            name: 'New Tab 1',
            type: 'new',
            disableFlag: true,
          },
        ]
        state.activeTabIdx = 0
        return state
      }
      if (closedTabIdx <= state.activeTabIdx) {
        state.activeTabIdx -= 1
      }
      pullAt(state.tabs, closedTabIdx)
    },
    newView: (state) => {
      const tabViewCount = state.tabs.filter(
        (tab) => tab.type === 'view'
      ).length
      state.tabs[state.activeTabIdx] = {
        name: 'View ' + (tabViewCount + 1),
        type: 'view',
        view: {
          layout: {
            rows: 1,
            columns: 1,
          },
          streamSrc: [],
        },
      }
      return state
    },
    openCamera: (state, action) => {
      // only works if the current tab is a view tab
      if ('view' in state.tabs[state.activeTabIdx]) {
        const tab = state.tabs[state.activeTabIdx] as ViewTab
        tab.view.streamSrc = tab.view.streamSrc.concat(action.payload)
        state.tabs[state.activeTabIdx] = tab
      }
      return state
    },
    // TODO: the VideoStream component does not rerender on closing stream
    closeCamera: (state, action) => {
      console.log(action.payload)
      // only works if the current tab is a view tab
      if ('view' in state.tabs[state.activeTabIdx]) {
        const tab = Object.assign({}, state.tabs[state.activeTabIdx]) as ViewTab
        const closeCamIdx = action.payload
        tab.view.streamSrc = [
          ...tab.view.streamSrc.slice(0, closeCamIdx),
          ...tab.view.streamSrc.slice(closeCamIdx + 1),
        ]
        state.tabs[state.activeTabIdx] = tab
      }
      return state
    },
    closeAllCameras: (state) => {
      if ('view' in state.tabs[state.activeTabIdx]) {
        const tab = Object.assign({}, state.tabs[state.activeTabIdx]) as ViewTab
        tab.view = {
          layout: {
            rows: 1,
            columns: 1,
          },
          streamSrc: [0],
        }
        state.tabs[state.activeTabIdx] = tab
      }
      return state
    },
    changeName: (state, action) => {
      state.tabs.forEach((tab, idx) => {
        if (idx === action.payload.id) {
          tab.name = action.payload.name
        }
      })
    },
    changeTabColor: (state, action) => {
      state.tabs.forEach((tab, idx) => {
        if (idx === action.payload.id) {
          tab.bookmarkColor = action.payload.color
        }
      })
    },
  },
})

export const {
  switchTab,
  createNewTab,
  closeTab,
  newView,
  changeName,
  changeTabColor,
  openCamera,
  closeCamera,
  closeAllCameras,
} = tab.actions

export default tab.reducer
