import React, { FC, ReactNode } from 'react'
import {
  alpha,
  Color,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material'
import {
  ColorPartial,
  PaletteColorOptions,
  PartialTypeObject,
} from '@mui/material/styles/createPalette'
import { Close, ExpandMore, Info } from '@mui/icons-material'

declare module '@mui/material/styles' {
  interface GradientPalette {
    light: PartialTypeObject
    dark: PartialTypeObject
  }

  interface PaletteColor extends ColorPartial {
    400: string
  }

  interface Palette {
    neutral: Color
    gradient: GradientPalette
  }

  interface PaletteOptions {
    neutral: PaletteColorOptions
    gradient: GradientPalette
  }
}

export interface IThemeProviderProps {
  children?: ReactNode
}

const shadows = {
  elevation1:
    '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  elevation2:
    '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)',
  elevation3:
    '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  elevation4:
    '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
  elevation5:
    '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
}

const theme = createTheme({
  typography: {
    fontFamily: 'OpenSans, sans-serif',
  },
  palette: {
    mode: 'dark',
    grey: {
      50: '#F7F7F7',
      100: '#EEEEEE',
      200: '#E3E3E3',
      300: '#D1D1D1',
      400: '#ACACAC',
      500: '#8B8B8B',
      600: '#646464',
      700: '#515151',
      800: '#333333',
      900: '#131313',
    },
    neutral: {
      50: '#F5F5F5',
      100: '#E9E9E9',
      200: '#D9D9D9',
      300: '#C4C4C4',
      400: '#9D9D9D',
      500: '#7B7B7B',
      600: '#555555',
      700: '#434343',
      800: '#262626',
    },
    primary: {
      50: '#EEF9E8',
      100: '#D4F0C5',
      200: '#B7E69F',
      300: '#94DB70',
      400: '#7FD456',
      500: '#66CC33',
      600: '#56BC2B',
      700: '#3FA721',
      800: '#249315',
      900: '#007100',
      main: '#7FD456',
      dark: '#3FA721',
      contrastText: '#FFFFFF',
    },
    info: {
      light: '#72D0FA',
      main: '#29B6F6',
      dark: '#0989CF',
    },
    warning: {
      light: '#FFC774',
      main: '#FFAB31',
      dark: '#F57C00',
    },
    error: {
      light: '#EF6E6E',
      main: '#EA4040',
      dark: '#DD0000',
    },
    gradient: {
      light: {
        background: {
          default:
            'linear-gradient(90deg, #66CC33 0%, #7FD456 48.95%, #66CC33 99.46%)',
        },
      },
      dark: {
        background: {
          default: 'linear-gradient(150.54deg, #3FA721 3.35%, #56BC2B 68.05%)',
        },
      },
    },
    background: {
      paper: '#262626',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiFormLabel-root': {
            position: 'relative',
            color: 'white',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            transform: 'none',
            marginBottom: '3px',
            '&.Mui-focused': {
              color: 'white',
            },
            '&.Mui-error': {
              color: theme.palette.error.main,
            },
          },
          '.MuiInputBase-root': {
            height: '2.75rem',
            background: theme.palette.grey[900],
            color: 'white',
            borderRadius: '4px',
            border: '1px solid transparent',
            marginTop: 0,
            boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.05)',
            overflow: 'hidden',
            '.MuiInput-input': {
              padding: '0.625rem 0.75rem !important',
              '&.MuiSelect-select': {
                paddingRight: '2.5rem',
              },
            },
            '&.MuiInputBase-sizeSmall': {
              height: '2rem',
              '.MuiInput-input': {
                paddingTop: '0.25rem !important',
                paddingBottom: '0.25rem !important',
              },
            },
            '&.Mui-focused': {
              borderColor: 'white',
            },
            '&.Mui-error': {
              borderColor: theme.palette.error.main,
            },
            '&::before': {
              display: 'none',
            },
            '&::after': {
              display: 'none',
            },
          },
          '&.Mui-select': {
            '.Mui-focused': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          '.MuiInputAdornment-positionStart': {
            marginLeft: '0.75rem',
          },
          '.MuiSvgIcon-root': {
            fill: 'white',
          },
          '.MuiSelect-icon': {
            marginRight: '0.75rem',
          },
          '.MuiAutocomplete-endAdornment': {
            marginRight: '0.75rem',
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          color: 'white',
          background: theme.palette.neutral[800],
          boxShadow: shadows.elevation4,
          '.MuiList-root': {
            padding: 0,
          },
          '.MuiMenuItem-root': {
            height: '2.5rem',
            padding: '0.25rem 1rem',
            borderRadius: '4px',
            '&.Mui-selected': {
              background: theme.palette.grey[800],
            },
            '&:hover': {
              background: alpha(theme.palette.grey[700], 0.2),
            },
          },
          '&.select-dropdown': {
            background: theme.palette.grey[900],
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: shadows.elevation2,
            '.MuiMenuItem-root': {
              height: '2.75rem',
              color: 'neutral.200',
              padding: '0.25rem 0.75rem',
              borderRadius: 0,
              '&.Mui-selected': {
                color: 'white',
                background: alpha(theme.palette.grey[700], 0.2),
              },
              '&:hover .MuiTouchRipple-root': {
                color: 'white',
              },
            },
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            height: 'auto !important',
            minHeight: '2.75rem',
            paddingBottom: 0,
            '&.MuiInputBase-sizeSmall': {
              minHeight: '2rem',
            },
          },
        },
        paper: ({ theme }) => ({
          color: 'white',
          background: theme.palette.grey[900],
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          boxShadow: shadows.elevation2,
          '.MuiAutocomplete-listbox': {
            padding: 0,
            '.MuiAutocomplete-option': {
              height: '2.75rem',
              color: theme.palette.neutral[200],
              padding: '0.25rem 0.75rem',
              '&.Mui-focused': {
                color: 'white',
                background: alpha(theme.palette.grey[700], 0.2),
              },
              '&[aria-selected="true"]': {
                color: 'white',
                background: alpha(theme.palette.grey[700], 0.2),
                '&.Mui-focused': {
                  background: alpha(theme.palette.grey[700], 0.4),
                },
              },
            },
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          height: '2.5rem',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          textAlign: 'center',
          letterSpacing: '0.02em',
          textTransform: 'none',
          boxShadow: 'none',
          '&.MuiButton-containedPrimary': {
            background: theme.palette.primary[500],
            '&:hover': {
              background: theme.palette.primary.dark,
            },
            '&.Mui-disabled': {
              color: theme.palette.primary[50],
            },
          },
          '&.MuiButton-outlinedPrimary': {
            color: 'white',
            borderColor: theme.palette.primary.main,
            '&:hover': {
              background: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
              boxShadow: shadows.elevation2,
            },
          },
          '&.MuiButton-outlinedSecondary': {
            color: 'white',
            borderColor: 'white',
            '&:hover': {
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
          },
          '&.MuiButton-textPrimary': {
            color: theme.palette.primary[500],
            '&:hover': {
              color: theme.palette.primary.dark,
              background: 'initial',
            },
          },
          '&.MuiButton-textSecondary': {
            color: 'white',
            '&:hover': {
              color: theme.palette.primary.dark,
              background: 'initial',
            },
          },
          '&.MuiButton-contained': {
            '&:hover': {
              boxShadow: shadows.elevation2,
            },
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: 'white',
          background: theme.palette.neutral[800],
          borderRadius: '0.5rem',
          boxShadow: shadows.elevation1,
          transition: 'all ease-in-out 0.2s',
          '&:hover': {
            background: theme.palette.grey[800],
            boxShadow: shadows.elevation3,
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '1.5rem',
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <Close className="ml-2 mr-0 w-5 text-inherit" />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          height: 'auto',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.5,
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          '&.MuiChip-sizeSmall': {
            padding: '0.125rem 0.5rem',
          },
          '&.MuiChip-colorDefault': {
            color: theme.palette.neutral[200],
            background: alpha(theme.palette.grey[500], 0.5),
          },
          '&.MuiChip-colorPrimary': {
            color: theme.palette.primary[600],
            background: alpha(theme.palette.primary[500] as string, 0.2),
            '&.MuiChip-outlined': {
              color: 'white',
              background: 'transparent',
            },
          },
          '&.MuiChip-colorInfo': {
            color: theme.palette.info.main,
            background: alpha(theme.palette.info.dark, 0.3),
          },
          '&.MuiChip-colorWarning': {
            color: theme.palette.warning.main,
            background: alpha(theme.palette.warning.dark, 0.2),
          },
          '&.MuiChip-colorError': {
            color: theme.palette.error.light,
            background: alpha(theme.palette.error.light, 0.2),
          },
          '.MuiChip-label': {
            padding: 0,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiTableRow-odd': {
            background: theme.palette.grey[800],
          },
          '&.MuiTableRow-even': {
            background: theme.palette.neutral[800],
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 0,
          color: 'white',
          fontSize: '1rem',
          lineHeight: 1.5,
          fontStyle: 'normal',
          padding: '0.5rem 2rem',
          verticalAlign: 'middle',
          '&.MuiTableCell-sizeSmall': {
            padding: '0.5rem 1rem',
          },
          '&.MuiTableCell-head': {
            fontWeight: 700,
            lineHeight: 1.25,
            padding: '0.5rem 1.5rem',
            '&.MuiTableCell-sizeSmall': {
              padding: '0.5rem 1rem',
            },
          },
          '.MuiTableSortLabel-root': {
            color: 'white !important',
            '.MuiTableSortLabel-icon': {
              display: 'none',
            },
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: 'white',
          padding: '0.25rem',
          '&:hover': {
            color: 'white !important',
          },
          '&.MuiIconButton-colorSecondary': {
            color: theme.palette.neutral[300],
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '2.25rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '2.25rem',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.25,
          letterSpacing: '-0.25px',
          textAlign: 'center',
          textTransform: 'none',
          padding: '0.5rem 0.75rem',
          '&.Mui-selected': {
            fontWeight: 700,
            letterSpacing: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          color: 'white',
          background: theme.palette.neutral[800],
          boxShadow: shadows.elevation5,
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: ({ theme }) => ({
          color: theme.palette.grey[200],
          fontSize: '0.75rem',
          fontWeight: 600,
          letterSpacing: 0,
          textTransform: 'uppercase',
          cursor: 'default',
          '.MuiLink-root': {
            color: theme.palette.grey[400],
          },
        }),
        separator: ({ theme }) => ({
          color: theme.palette.grey[400],
          margin: '0 0.25rem',
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          background: alpha(theme.palette.grey[500], 0.1),
          borderRadius: '1rem !important',
          '&::before': {
            display: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMore />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          height: '2.5rem',
          minHeight: '2.5rem',
          fontSize: '1rem',
          flexDirection: 'row-reverse',
          paddingRight: '1.5rem',
        }),
        expandIconWrapper: {
          marginRight: '0.5rem',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '1rem 1.5rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '20rem',
          fontSize: '0.875rem',
          lineHeight: '1.1875rem',
          borderRadius: '0.5rem',
          padding: '0.25rem 0.75rem',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <Info />,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '7px 14px',
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.1875rem',
          boxShadow: shadows.elevation5,
          '&.MuiAlert-filled': {
            color: 'black',
            '.MuiAlert-action .MuiButtonBase-root': {
              color: 'black !important',
            },
          },
        }),
        action: {
          paddingLeft: '4px',
        },
      },
    },
  },
})

export const ThemeProvider: FC<IThemeProviderProps> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
