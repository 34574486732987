import { PlusIcon } from 'assets/icons'
import { useTypedDispatch } from 'common-lib/hooks/useTypedStore'
import { createNewTab } from 'store/slices/tab'
import { FC } from 'react'
import classNames from 'classnames'

interface NewTabButtonProps {
  roundedCorner?: 'left' | 'right'
}

const NewTabButton: FC<NewTabButtonProps> = ({ roundedCorner }) => {
  const dispatch = useTypedDispatch()
  const classes = classNames(
    'flex w-6 items-center rounded-full justify-center hover:cursor-pointer hover:bg-primary-50',
    {
      'rounded-tl-lg': roundedCorner === 'left',
      'rounded-tr-lg': roundedCorner === 'right',
    }
  )

  return (
    <div
      onClick={() => dispatch(createNewTab())}
      className={classes}
      data-testid="new-tab-container"
    >
      <PlusIcon className="h-2 fill-white" />
    </div>
  )
}

export default NewTabButton
