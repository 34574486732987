import { FC, useEffect, useRef, useState, memo } from 'react'
import classNames from 'classnames'
import { useDrag, useDrop } from 'react-dnd'
import { Receiver } from 'services/webrtc'
import { CloseIcon } from 'assets/icons'
import {
  useTypedDispatch,
  useTypedSelector,
} from 'common-lib/hooks/useTypedStore'
import {
  setCurrentTime,
  setStreamOpenTime,
  StreamStatus,
} from 'store/slices/channel'
import { StreamName } from './StreamName'
import { StreamTimer } from './StreamTimer'
import { videoStreamsData as streams } from 'constants/videoStreams'

interface VideoStreamProps {
  name?: string
  id: string
  channelId: number
  className?: string
  onClick?: () => void
  onReorder?: (from: string, to: string) => void
  onClose?: () => unknown
}

const VideoStream: FC<VideoStreamProps> = ({
  name,
  id,
  channelId,
  className,
  onClick,
  onReorder,
  onClose,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'camera-view',
      item: { id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id]
  )

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'camera-view',
      drop: (item: { id: string }) => {
        if (onReorder) {
          onReorder(item.id, id)
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [id, onReorder]
  )

  // Refs
  // videoRef points to the video element for this stream
  const videoRef = useRef<HTMLVideoElement>(null)
  // timerRef points to a timer updating currentTime in redux once a second
  const timerRef = useRef<NodeJS.Timer | number>()
  // points to playback time, avoiding redundant reload of playback stream
  const streamOpenTimeRef = useRef<number>()

  // Local state
  const [loading, setLoading] = useState(true)
  const [startTime, setStartTime] = useState<number | undefined>(undefined)

  // Global state
  // selector for each necessary state item only to avoid redundant re-rendering
  const streamOpenTime = useTypedSelector(
    ({ channel }) => channel.timers[channelId]?.streamOpenTime
  )
  const status = useTypedSelector(
    ({ channel }) => channel.timers[channelId]?.status
  )
  const selectedChannelId = useTypedSelector(
    ({ channel }) => channel.selectedChannelId
  )
  const dispatch = useTypedDispatch()

  // This effect creates a playback session if it should according to redux.
  // When the session is created, it updates the local url state variable
  useEffect(() => {
    if (
      selectedChannelId === channelId &&
      streamOpenTimeRef.current !== streamOpenTime
    ) {
      if (status === StreamStatus.Playback) {
        streamOpenTimeRef.current = streamOpenTime
        setStartTime(streamOpenTime)
        return
      }

      if (streamOpenTime !== undefined && status === StreamStatus.Live) {
        setStartTime(undefined)
      }
    }
  }, [selectedChannelId, status, streamOpenTime])

  // This effect runs each time url changes; it creates a WebRTC receiver, and
  // sets loading to true initially, switching it back to false when
  // the WebRTC connection is setup.
  useEffect(() => {
    if (!videoRef.current) {
      console.log('what to do when this happens, !videoRef.current')
      return
    }
    setLoading(true)
    const video = videoRef.current
    const receiver = new Receiver(
      video,
      streams.items[channelId - 1].metadata.name,
      startTime
    )

    receiver.onConnectionStateChanged((state) => {
      if (state === 'connected') {
        setLoading(false)

        if (selectedChannelId !== channelId) {
          // TODO: Change this to take playback and pause into account
          dispatch(
            setStreamOpenTime({
              channelId,
              streamOpenTime: Date.now(),
            })
          )
        }
      }
    })

    return () => receiver.terminate()
  }, [startTime])

  // This effect updates currentTime in redux once a second. It stores the timer
  // in timerRef.
  useEffect(() => {
    if (status !== StreamStatus.Paused) {
      timerRef.current = setInterval(() => {
        if (!videoRef.current) {
          console.log('!videoRef.current oh no')
          return
        }
        const openTime = streamOpenTime ?? new Date().getTime()
        const newTime = openTime + videoRef.current.currentTime * 1000
        dispatch(
          setCurrentTime({
            channelId,
            currentTime: newTime,
          })
        )
      }, 1000)

      return () => {
        if (timerRef.current) clearInterval(timerRef.current)
      }
    }
  }, [streamOpenTime, status])

  /**
   * This effect controls the video behavior based on the status dispatched from Panel
   */
  useEffect(() => {
    if (!videoRef.current) return
    const videoEl = videoRef.current
    switch (status) {
      case StreamStatus.Paused:
        videoEl.pause()
        break
      default:
        if (videoEl.paused && videoEl.played.length) {
          videoEl.play().catch((e) => {
            console.warn(e)
          })
        }
    }
  }, [status, videoRef.current])

  return (
    <div
      ref={drop}
      className={classNames(
        'flex h-full w-full flex-col overflow-hidden',
        isOver &&
          'border-2 border-dashed border-secondary bg-white bg-opacity-20'
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'changed_height relative flex h-full max-w-full items-center justify-center bg-black',
          isDragging && 'border-grey opacity-50',
          className
        )}
        ref={drag}
      >
        {loading && (
          <div className="absolute mx-auto h-12 w-12 animate-spin rounded-full border border-solid border-secondary border-t-transparent" />
        )}
        <video
          key={channelId}
          ref={videoRef}
          className="max-h-full max-w-full"
          muted
          controls={false}
          autoPlay
          playsInline
        />
        <div className="absolute top-3 right-3 left-3 flex justify-between text-xs text-white">
          <StreamName channelId={channelId} name={name} />
          <div className="rounded bg-black bg-opacity-50 px-2">
            <div className="flex h-full items-center gap-3">
              <StreamTimer channelId={channelId} />
              {onClose && (
                <button onClick={onClose}>
                  <CloseIcon className="w-3 fill-white" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MemoVideoStream = memo(VideoStream)
