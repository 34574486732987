import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum StreamStatus {
  Live,
  Playback,
  Paused,
}

export interface ChannelTimer {
  streamOpenTime: number
  currentTime: number
  status: StreamStatus
}

export enum StreamControls {
  StepBackward,
  StepForward,
  PlayBackward,
  Pause,
  PlayForward,
  Live,
}

export interface ChannelState {
  selectedChannelId: number
  timers: {
    [key: number]: ChannelTimer
  }
}

const initialState: ChannelState = {
  selectedChannelId: -1,
  timers: {},
}

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setStreamOpenTime: (
      state: ChannelState,
      action: PayloadAction<{ channelId: number; streamOpenTime: number }>
    ) => {
      const { channelId, streamOpenTime } = action.payload
      state.timers[channelId] = {
        ...(state.timers[channelId] ?? {}),
        streamOpenTime,
      }
    },
    setCurrentTime: (
      state: ChannelState,
      action: PayloadAction<{ channelId: number; currentTime: number }>
    ) => {
      const { channelId, currentTime } = action.payload
      state.timers[channelId] = {
        ...(state.timers[channelId] ?? {}),
        currentTime,
      }
    },
    setStreamStatus: (
      state: ChannelState,
      action: PayloadAction<{ channelId: number; status: StreamStatus }>
    ) => {
      const { channelId, status } = action.payload
      const currentChannelState = state.timers[channelId] ?? {}
      state.timers[channelId] = {
        ...currentChannelState,
        status,
        streamOpenTime:
          status === StreamStatus.Paused
            ? currentChannelState.currentTime
            : currentChannelState.streamOpenTime,
      }
    },
    setSelectedChannelId: (state: ChannelState, action) => {
      state.selectedChannelId = action.payload ?? -1
    },
  },
})

export const {
  setStreamOpenTime,
  setCurrentTime,
  setSelectedChannelId,
  setStreamStatus,
} = channelSlice.actions

export default channelSlice.reducer
