import Bar from './bar/Bar'
import TabContent from './TabContent'
import { useFetchTab } from 'services'
import Panel from './Panel'
const Canvas = () => {
  useFetchTab()

  return (
    <div className="canvas relative border-4 border-black">
      <div className="bg-black">
        <Bar />
      </div>
      <div className="tabcontent absolute flex grow flex-col bg-primary">
        <TabContent />
      </div>
      <div className="h-10">
        <Panel />
      </div>
    </div>
  )
}

export { Canvas }
