import React from 'react'
import { useTypedDispatch } from '../../common-lib/hooks/useTypedStore'
import { newView } from '../../store/slices/tab'

const NewTabDashBoard = () => {
  const dispatch = useTypedDispatch()
  return (
    <div className="grid h-full grid-flow-row grid-cols-4 justify-between p-50 text-white">
      <div></div>

      <div
        className="py-10 text-2xl font-bold text-white"
        data-testid="spotter.web.text"
      >
        SpotterWeb
        <p className="text-xl font-medium">by Mirasys</p>
      </div>

      <div></div>

      <div></div>

      <div></div>

      <div>
        <p className="text-xl font-semibold">Open</p>
        <ul className="space-y-2 p-2 text-sm text-secondary hover:cursor-pointer">
          <li onClick={() => dispatch(newView())}>New View</li>
          <li>New Tool</li>
          <li>Settings</li>
        </ul>
      </div>

      <div>
        <p className="text-xl font-semibold">Walkthrough</p>
        <ul className="space-y-2 p-2 text-sm text-secondary hover:cursor-pointer">
          <li>Get started with SpotterWeb</li>
          <li>Quick tour</li>
          <li>Courses</li>
        </ul>
      </div>

      <div></div>
    </div>
  )
}

export default NewTabDashBoard
