import { createSlice } from '@reduxjs/toolkit'

export interface IUserProfile {
  id: string
  email: string
  firstName: string
  lastName: string
}

export interface IAuthState {
  user: IUserProfile
}

const initialState: IAuthState = {
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = {
        id: action.payload.id,
        email: action.payload.identities[0].identifier,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }
    },
  },
})

export const { setUser } = authSlice.actions
export default authSlice.reducer
