import { FC, SVGProps } from 'react'
import classNames from 'classnames'

export interface ContextMenuItemProps {
  label: string
  value?: string | number
  onClick?: () => unknown
  icon?: FC<SVGProps<SVGSVGElement>>
}

interface ContextMenuProps {
  className?: string
  items: ContextMenuItemProps[]
  onSelect?: (value?: number | string) => unknown
  onClose?: () => unknown
}

const ContextMenu: FC<ContextMenuProps> = ({
  className,
  items,
  onClose,
  onSelect,
}) => {
  const classes = classNames(
    'flex flex-col gap-0.5 bg-black bg-opacity-90 rounded-b shadow-lg p-1 text-white',
    className
  )

  const handleClick = (item: ContextMenuItemProps) => () => {
    item.onClick?.()
    onSelect?.(item.value)
    onClose?.()
  }

  return (
    <dl className={classes} data-testid="context-menu">
      {items.map((item, index) => (
        <dt
          className="flex h-6 items-center gap-2 rounded bg-primary px-2 hover:cursor-pointer hover:bg-primary-100"
          key={item.value ?? index}
          onClick={handleClick(item)}
        >
          {item.icon && (
            <item.icon
              data-testid="context-menu-icon"
              className="h-3 fill-white"
            />
          )}
          <p className="text-xs">{item.label}</p>
        </dt>
      ))}
    </dl>
  )
}

export default ContextMenu
