import { Canvas } from 'components/Canvas/Canvas'
import LeftSidebar from 'components/LeftSidebar'
import RightSidebar from 'components/RightSidebar'
import { Timeline } from 'components/Timeline'

const MainPage = () => (
  <div className="flex h-screen flex-row" data-testid="main-page">
    <div className="grow-1 basis-52">
      <LeftSidebar />
    </div>
    <div className="grow-10 flex basis-full flex-col">
      <Canvas />
      <div className="w-full">
        <Timeline />
      </div>
    </div>
    <div className="grow-1 basis-67 bg-primary-100">
      <RightSidebar />
    </div>
  </div>
)

export { MainPage }
