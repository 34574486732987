import { format } from 'date-fns'
import { useCallback, useEffect, useRef } from 'react'
import { DataSet } from 'vis-data/esnext'
import { Timeline as VisTimeline } from 'vis-timeline/esnext'
import 'assets/styles/components/timeline.css'
import { Pointer } from './components/Pointer'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import { useVideoControls } from 'hooks'

const Timeline = () => {
  // Refs
  // timelineDiv points to the div element of the timeline
  const timelineDiv = useRef<HTMLDivElement>(null)
  // timeline points to the VisTimeline object initialized from timelineDiv,
  // allowing programmatic access to change the timeline time
  const timeline = useRef<VisTimeline | null>(null)

  // access the selected channel only instead of all channels in store
  // NO useMemo here to avoid abusing memoization as currentTime is changed every second
  const { currentTime = Date.now() } = useTypedSelector(
    ({ channel }) => channel.timers[channel.selectedChannelId] ?? {}
  )
  const { jumpToTime } = useVideoControls()

  const setPointerTime = (time: Date) => {
    timeline.current?.moveTo(time, {
      animation: { duration: 50, easingFunction: 'linear' },
    })
  }

  // This callback fires when the user has scrolled the timeline, the user has
  // moved the timeline, or when the moveTo() function is called.
  const onRangeChanged = useCallback(
    (e: { byUser: boolean; event: Event; start: Date; end: Date }) => {
      // ignore non-user events
      if (!e.byUser) {
        return
      }

      if (timeline.current === null) {
        console.log('timeline.current === null!!')
        return
      }

      // if the user scrolls, do nothing as the timeline is already re-centered via the last effect
      if (e.event?.type === 'wheel') {
        return
      }

      const now = new Date()
      const pointedTime = new Date((e.start.getTime() + e.end.getTime()) / 2)
      if (now < pointedTime) {
        setPointerTime(now)
      }
      jumpToTime(pointedTime.getTime())
    },
    [jumpToTime]
  )

  // This effect initializes the timeline after a render.
  useEffect(() => {
    // If vis-timeline has not yet been initialized, initialize it.
    if (timeline.current === null) {
      // React should have put the ref of the div here
      if (timelineDiv.current === null) {
        console.log('timelineDiv.current === null!!')
        return
      }

      const items = new DataSet([])
      const now = Date.now()
      const options = {
        orientation: 'top',
        zoomMax: 200000000000,
        zoomMin: 2000,
        start: new Date(now - 24 * 60 * 60 * 1000),
        end: new Date(now + 24 * 60 * 60 * 1000),
        width: '100%',
        height: '90px',
      }

      timeline.current = new VisTimeline(timelineDiv.current, items, options)
    }

    timeline.current.on('rangechanged', onRangeChanged)

    return () => {
      if (timeline.current !== null) {
        timeline.current.off('rangechanged', onRangeChanged)
      }
    }
  }, [onRangeChanged])

  // This effect moves the pointer on the timeline and centers, every time
  // currentTime updates.
  useEffect(() => {
    setPointerTime(new Date(currentTime))
  }, [currentTime])

  return (
    <div className="relative bg-primary-100">
      <div className="pointer">
        <div className="current_time" data-testid="div-date">
          {format(new Date(currentTime), 'HH:mm:ss dd/MM/yyyy')}
        </div>
        <Pointer />
      </div>
      <div
        id="timeline"
        ref={timelineDiv}
        className="slider text-sm"
        data-testid="timeline"
      />
    </div>
  )
}

export { Timeline }
