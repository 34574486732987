import { FC, SVGProps } from 'react'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import {
  PauseGreenIcon,
  PauseIcon,
  PlaybackIcon,
  PlayForwardIcon,
  SkipGreenIcon,
  StepBackIcon,
  StepForwardIcon,
} from 'assets/icons'
import './panel.css'
import { StreamControls } from 'store/slices/channel'
import classNames from 'classnames'
import { useVideoControls } from 'hooks'

/**
 * this will be removed when the all features are ready
 */
const disabledControls = [
  StreamControls.PlayBackward,
  StreamControls.StepBackward,
  StreamControls.StepForward,
]

const Panel = () => {
  const selectedChannelId = useTypedSelector(
    ({ channel }) => channel.selectedChannelId ?? -1
  )
  const { setPaused, setLive, isLive, isPaused, isPlayback } =
    useVideoControls()
  const disabled = selectedChannelId === -1

  const handleClick = (action: StreamControls) => () => {
    switch (action) {
      case StreamControls.Pause:
        setPaused(true)
        break
      case StreamControls.Live:
        setLive()
        break
      case StreamControls.PlayForward:
        if (isPaused) setPaused(false)
        break
    }
  }

  const className = classNames('hover:cursor-pointer', {
    'hover:drop-shadow-primary': !disabled,
    'opacity-75 disabled hover:cursor-not-allowed': disabled,
  })

  const getIconClassName = (action: StreamControls) => {
    let active = isLive && action === StreamControls.Live
    active = active || (isPaused && action === StreamControls.Pause)
    active = active || (isPlayback && action === StreamControls.PlayForward)
    const disabled =
      disabledControls.includes(action) ||
      (isLive && action === StreamControls.PlayForward)
    return classNames(className, {
      'fill-secondary': active,
      'fill-white': !active,
      'opacity-75 hover:cursor-not-allowed': disabled,
    })
  }

  const renderControlButton = (
    IconComponent: FC<SVGProps<SVGSVGElement>>,
    action: StreamControls
  ) => {
    return (
      <IconComponent
        onClick={handleClick(action)}
        className={getIconClassName(action)}
      />
    )
  }

  return (
    <div className="controller flex items-center justify-center gap-8 py-1 pr-4">
      <div className="flex items-center justify-center gap-1">
        {renderControlButton(StepBackIcon, StreamControls.StepBackward)}
        {renderControlButton(StepForwardIcon, StreamControls.StepForward)}
      </div>
      <div className="flex items-center justify-center gap-1">
        {renderControlButton(PlaybackIcon, StreamControls.PlayBackward)}
        <div onClick={handleClick(StreamControls.Pause)} className={className}>
          {isPaused ? <PauseGreenIcon /> : <PauseIcon />}
        </div>
        {renderControlButton(PlayForwardIcon, StreamControls.PlayForward)}
      </div>
      {renderControlButton(SkipGreenIcon, StreamControls.Live)}
    </div>
  )
}

export default Panel
