import { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  useTypedDispatch,
  useTypedSelector,
} from 'common-lib/hooks/useTypedStore'
import './View.css'
import { MemoVideoStream as VideoStream } from 'components/VideoStream'
import { profiles } from 'components/LeftSidebar'
import { closeCamera, ViewTab } from 'store/slices/tab'
import { setSelectedChannelId } from 'store/slices/channel'

interface ICameraStream {
  id: string
  channelId: number
}

// helper function to auto arrange camera views
const autoLayout = (count: number) => {
  if (count < 1) {
    return { row: 0, column: 0 }
  }

  const column = Math.ceil(Math.sqrt(count))
  const row = Math.ceil(count / column)
  return { row, column }
}

// render active tab here
const View = () => {
  const activeTabView = useTypedSelector(
    ({ tab: { tabs, activeTabIdx } }) => (tabs[activeTabIdx] as ViewTab).view
  )
  const dispatch = useTypedDispatch()
  const selectedChannelId = useTypedSelector(
    ({ channel }) => channel.selectedChannelId
  )

  const [cameras, setCameras] = useState<ICameraStream[]>([])

  useEffect(() => {
    setCameras(
      (activeTabView?.streamSrc || []).map((channelId) => {
        /**
         * If current camera is exits, we do not generate new camera with new ID
         * Else we generate new camera with new ID
         */
        const currentCamera = cameras.find(
          (camera) => camera.channelId === channelId
        )

        if (currentCamera)
          return {
            ...currentCamera,
          }

        return {
          id: Math.random().toString(),
          channelId,
        }
      })
    )
  }, [activeTabView])

  // select a stream if click on it
  // un-select a stream if click on it again
  const handleSelectStream = (channelId: number) => {
    if (channelId === selectedChannelId) {
      dispatch(setSelectedChannelId(-1))
      return
    }
    dispatch(setSelectedChannelId(channelId))
  }

  const handleCloseStream = (idx: number) => () => {
    dispatch(closeCamera(idx))
  }

  const channelList = profiles[0]?.ProfileNodes?.[0].ProfileNodes ?? []
  const channelDict = new Map<number, Record<string, unknown>>()
  channelList.forEach((channel) => {
    if (channel.Camera) {
      channelDict.set(channel.Camera.Channel, channel.Camera)
    }
  })

  const onReorderCameras = useCallback((fromId: string, toId: string) => {
    setCameras((prev) => {
      const from = prev.findIndex((item) => item.id === fromId)
      const to = prev.findIndex((item) => item.id === toId)
      const newState = [...prev]
      const item = newState.splice(from, 1)
      newState.splice(to, 0, ...item)
      return newState
    })
  }, [])

  const layout = autoLayout(activeTabView?.streamSrc.length || 0)
  const classNames = `h-full grid cols-${layout.column} bg-primary`

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames}>
        {cameras.map((camera, i) => {
          if (!camera.channelId) {
            return null
          }
          return (
            <VideoStream
              key={`${camera.id}-${camera.channelId}`}
              id={camera.id}
              name={channelDict.get(camera.channelId)?.Name as string}
              className={
                selectedChannelId === camera.channelId
                  ? 'border-2 border-secondary'
                  : 'hover:border-2'
              }
              channelId={camera.channelId}
              onClick={() => handleSelectStream(camera.channelId)}
              onReorder={onReorderCameras}
              onClose={handleCloseStream(i)}
            />
          )
        })}
      </div>
    </DndProvider>
  )
}

export default View
