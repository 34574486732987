import { useTypedSelector } from 'common-lib/hooks/useTypedStore'
import NewTabDashBoard from './NewTabDashBoard'
import Tool from './tool/Tool'
import View from './view/View'

const TabContent = () => {
  let tabContent = null
  const tabs = useTypedSelector(({ tab: { tabs } }) => tabs)
  if (tabs.length === 0) {
    tabContent = <div></div>
  } else {
    const activeTabType = useTypedSelector(
      ({ tab: { tabs, activeTabIdx } }) => tabs[activeTabIdx].type
    )

    switch (activeTabType) {
      case 'view':
        tabContent = <View />
        break
      case 'tool':
        tabContent = <Tool />
        break
      case 'new':
        tabContent = <NewTabDashBoard />
    }
  }
  return <div className="relative h-0 grow">{tabContent}</div>
}

export default TabContent
