import { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ToastContainer } from 'react-toastify'
import { SnackbarProvider, ThemeProvider } from 'common-lib/components'
import { AuthGuard } from 'common-lib/providers/AuthGuard'
import Routing from './routes'

const App = () => {
  return (
    <StrictMode>
      <ThemeProvider>
        <SnackbarProvider>
          <DndProvider backend={HTML5Backend}>
            <AuthGuard>
              <Routing />
            </AuthGuard>
          </DndProvider>
        </SnackbarProvider>
        <ToastContainer />
      </ThemeProvider>
    </StrictMode>
  )
}
export { App }
