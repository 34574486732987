export const videoStreamsData = JSON.parse(`{
    "apiVersion": "recorder.mirasys.com/v1alpha1",
    "items": [
        {
            "apiVersion": "recorder.mirasys.com/v1alpha1",
            "kind": "VideoStream",
            "metadata": {
                "annotations": {
                    "kubectl.kubernetes.io/last-applied-configuration": "{\\"apiVersion\\":\\"recorder.mirasys.com/v1alpha1\\",\\"kind\\":\\"VideoStream\\",\\"metadata\\":{\\"annotations\\":{},\\"labels\\":{\\"dev\\":\\"bac6dd82-999e-4876-9f81-f00568efdffb\\",\\"rec\\":\\"a87df1cb-3062-4abc-a55e-4c6d5b421548\\",\\"vch\\":\\"9c143ed6-5855-4a5f-9984-a55b7f49735d\\"},\\"name\\":\\"14956ec1-3c5f-4b71-8d1d-10afc77ebcfa\\",\\"namespace\\":\\"test-organization\\"},\\"spec\\":{\\"description\\":\\"Video stream 3 description\\",\\"displayName\\":\\"Video stream 3\\",\\"enabled\\":true}}\\n"
                },
                "creationTimestamp": "2023-06-12T12:09:02Z",
                "generation": 2,
                "labels": {
                    "dev": "bac6dd82-999e-4876-9f81-f00568efdffb",
                    "rec": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                    "vch": "9c143ed6-5855-4a5f-9984-a55b7f49735d"
                },
                "managedFields": [
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:metadata": {
                                "f:annotations": {
                                    ".": {},
                                    "f:kubectl.kubernetes.io/last-applied-configuration": {}
                                },
                                "f:labels": {
                                    ".": {},
                                    "f:dev": {},
                                    "f:rec": {},
                                    "f:vch": {}
                                }
                            },
                            "f:spec": {
                                ".": {},
                                "f:description": {},
                                "f:displayName": {},
                                "f:enabled": {}
                            }
                        },
                        "manager": "kubectl-client-side-apply",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:02Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:spec": {
                                "f:bitrate": {},
                                "f:bitrateMode": {},
                                "f:compression": {},
                                "f:extraParameters": {
                                    ".": {},
                                    "f:apiVersion": {},
                                    "f:kind": {},
                                    "f:spec": {}
                                },
                                "f:framerate": {},
                                "f:isSlowFramerateUsed": {},
                                "f:passiveMode": {},
                                "f:quality": {},
                                "f:resolution": {
                                    ".": {},
                                    "f:height": {},
                                    "f:width": {}
                                },
                                "f:slowFramerate": {},
                                "f:transport": {},
                                "f:useForRecording": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:37Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:status": {
                                ".": {},
                                "f:capabilities": {
                                    ".": {},
                                    "f:compressions": {},
                                    "f:internalStates": {},
                                    "f:isRecordingSupported": {},
                                    "f:isSlowFramerateSupported": {},
                                    "f:transports": {}
                                },
                                "f:channelNumber": {},
                                "f:channelRef": {},
                                "f:conditions": {},
                                "f:deviceRef": {},
                                "f:recorderRef": {},
                                "f:streamNumber": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "subresource": "status",
                        "time": "2023-06-12T12:10:09Z"
                    }
                ],
                "name": "14956ec1-3c5f-4b71-8d1d-10afc77ebcfa",
                "namespace": "test-organization",
                "resourceVersion": "702142",
                "uid": "fb600fa0-3f87-4df7-b9bc-955cac689149"
            },
            "spec": {
                "bitrate": 0,
                "bitrateMode": "CBR",
                "compression": "Undefined",
                "description": "Video stream 3 description",
                "displayName": "Video stream 3",
                "enabled": true,
                "extraParameters": {
                    "apiVersion": "v1alpha1",
                    "kind": "extraParameters",
                    "spec": {}
                },
                "framerate": 30,
                "isSlowFramerateUsed": false,
                "passiveMode": false,
                "quality": 60,
                "resolution": {
                    "height": 1080,
                    "width": 1920
                },
                "slowFramerate": 5,
                "transport": "Automatic",
                "useForRecording": false
            },
            "status": {
                "capabilities": {
                    "compressions": [],
                    "internalStates": "",
                    "isRecordingSupported": false,
                    "isSlowFramerateSupported": false,
                    "transports": []
                },
                "channelNumber": 3,
                "channelRef": "9c143ed6-5855-4a5f-9984-a55b7f49735d",
                "conditions": [
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Added"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Connected"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "CapabilitiesReceived"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Configured"
                    }
                ],
                "deviceRef": "bac6dd82-999e-4876-9f81-f00568efdffb",
                "recorderRef": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                "streamNumber": 0
            }
        },
        {
            "apiVersion": "recorder.mirasys.com/v1alpha1",
            "kind": "VideoStream",
            "metadata": {
                "annotations": {
                    "kubectl.kubernetes.io/last-applied-configuration": "{\\"apiVersion\\":\\"recorder.mirasys.com/v1alpha1\\",\\"kind\\":\\"VideoStream\\",\\"metadata\\":{\\"annotations\\":{},\\"labels\\":{\\"dev\\":\\"bac6dd82-999e-4876-9f81-f00568efdffb\\",\\"rec\\":\\"a87df1cb-3062-4abc-a55e-4c6d5b421548\\",\\"vch\\":\\"9c143ed6-5855-4a5f-9984-a55b7f49735d\\"},\\"name\\":\\"45b1a545-9590-4a37-b792-3213518f3297\\",\\"namespace\\":\\"test-organization\\"},\\"spec\\":{\\"description\\":\\"Video stream 4 description\\",\\"displayName\\":\\"Video stream 4\\",\\"enabled\\":true}}\\n"
                },
                "creationTimestamp": "2023-06-12T12:09:02Z",
                "generation": 2,
                "labels": {
                    "dev": "bac6dd82-999e-4876-9f81-f00568efdffb",
                    "rec": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                    "vch": "9c143ed6-5855-4a5f-9984-a55b7f49735d"
                },
                "managedFields": [
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:metadata": {
                                "f:annotations": {
                                    ".": {},
                                    "f:kubectl.kubernetes.io/last-applied-configuration": {}
                                },
                                "f:labels": {
                                    ".": {},
                                    "f:dev": {},
                                    "f:rec": {},
                                    "f:vch": {}
                                }
                            },
                            "f:spec": {
                                ".": {},
                                "f:description": {},
                                "f:displayName": {},
                                "f:enabled": {}
                            }
                        },
                        "manager": "kubectl-client-side-apply",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:02Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:spec": {
                                "f:bitrate": {},
                                "f:bitrateMode": {},
                                "f:compression": {},
                                "f:extraParameters": {
                                    ".": {},
                                    "f:apiVersion": {},
                                    "f:kind": {},
                                    "f:spec": {}
                                },
                                "f:framerate": {},
                                "f:isSlowFramerateUsed": {},
                                "f:passiveMode": {},
                                "f:quality": {},
                                "f:resolution": {
                                    ".": {},
                                    "f:height": {},
                                    "f:width": {}
                                },
                                "f:slowFramerate": {},
                                "f:transport": {},
                                "f:useForRecording": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:41Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:status": {
                                ".": {},
                                "f:capabilities": {
                                    ".": {},
                                    "f:compressions": {},
                                    "f:internalStates": {},
                                    "f:isRecordingSupported": {},
                                    "f:isSlowFramerateSupported": {},
                                    "f:transports": {}
                                },
                                "f:channelNumber": {},
                                "f:channelRef": {},
                                "f:conditions": {},
                                "f:deviceRef": {},
                                "f:recorderRef": {},
                                "f:streamNumber": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "subresource": "status",
                        "time": "2023-06-12T12:10:09Z"
                    }
                ],
                "name": "45b1a545-9590-4a37-b792-3213518f3297",
                "namespace": "test-organization",
                "resourceVersion": "702143",
                "uid": "8cc85382-d7a4-44ed-87e6-458cdb6a1ab0"
            },
            "spec": {
                "bitrate": 0,
                "bitrateMode": "CBR",
                "compression": "Undefined",
                "description": "Video stream 4 description",
                "displayName": "Video stream 4",
                "enabled": true,
                "extraParameters": {
                    "apiVersion": "v1alpha1",
                    "kind": "extraParameters",
                    "spec": {}
                },
                "framerate": 30,
                "isSlowFramerateUsed": false,
                "passiveMode": false,
                "quality": 60,
                "resolution": {
                    "height": 1080,
                    "width": 1920
                },
                "slowFramerate": 5,
                "transport": "Automatic",
                "useForRecording": false
            },
            "status": {
                "capabilities": {
                    "compressions": [],
                    "internalStates": "",
                    "isRecordingSupported": false,
                    "isSlowFramerateSupported": false,
                    "transports": []
                },
                "channelNumber": 4,
                "channelRef": "9c143ed6-5855-4a5f-9984-a55b7f49735d",
                "conditions": [
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Added"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Connected"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "CapabilitiesReceived"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Configured"
                    }
                ],
                "deviceRef": "bac6dd82-999e-4876-9f81-f00568efdffb",
                "recorderRef": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                "streamNumber": 0
            }
        },
        {
            "apiVersion": "recorder.mirasys.com/v1alpha1",
            "kind": "VideoStream",
            "metadata": {
                "annotations": {
                    "kubectl.kubernetes.io/last-applied-configuration": "{\\"apiVersion\\":\\"recorder.mirasys.com/v1alpha1\\",\\"kind\\":\\"VideoStream\\",\\"metadata\\":{\\"annotations\\":{},\\"labels\\":{\\"dev\\":\\"bac6dd82-999e-4876-9f81-f00568efdffb\\",\\"rec\\":\\"a87df1cb-3062-4abc-a55e-4c6d5b421548\\",\\"vch\\":\\"9c143ed6-5855-4a5f-9984-a55b7f49735d\\"},\\"name\\":\\"8309eeb7-66b3-409f-9cf7-4e80347a7c7f\\",\\"namespace\\":\\"test-organization\\"},\\"spec\\":{\\"description\\":\\"Video stream 2 description\\",\\"displayName\\":\\"Video stream 2\\",\\"enabled\\":true}}\\n"
                },
                "creationTimestamp": "2023-06-12T12:09:02Z",
                "generation": 2,
                "labels": {
                    "dev": "bac6dd82-999e-4876-9f81-f00568efdffb",
                    "rec": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                    "vch": "9c143ed6-5855-4a5f-9984-a55b7f49735d"
                },
                "managedFields": [
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:metadata": {
                                "f:annotations": {
                                    ".": {},
                                    "f:kubectl.kubernetes.io/last-applied-configuration": {}
                                },
                                "f:labels": {
                                    ".": {},
                                    "f:dev": {},
                                    "f:rec": {},
                                    "f:vch": {}
                                }
                            },
                            "f:spec": {
                                ".": {},
                                "f:description": {},
                                "f:displayName": {},
                                "f:enabled": {}
                            }
                        },
                        "manager": "kubectl-client-side-apply",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:02Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:spec": {
                                "f:bitrate": {},
                                "f:bitrateMode": {},
                                "f:compression": {},
                                "f:extraParameters": {
                                    ".": {},
                                    "f:apiVersion": {},
                                    "f:kind": {},
                                    "f:spec": {}
                                },
                                "f:framerate": {},
                                "f:isSlowFramerateUsed": {},
                                "f:passiveMode": {},
                                "f:quality": {},
                                "f:resolution": {
                                    ".": {},
                                    "f:height": {},
                                    "f:width": {}
                                },
                                "f:slowFramerate": {},
                                "f:transport": {},
                                "f:useForRecording": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:42Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:status": {
                                ".": {},
                                "f:capabilities": {
                                    ".": {},
                                    "f:compressions": {},
                                    "f:internalStates": {},
                                    "f:isRecordingSupported": {},
                                    "f:isSlowFramerateSupported": {},
                                    "f:transports": {}
                                },
                                "f:channelNumber": {},
                                "f:channelRef": {},
                                "f:conditions": {},
                                "f:deviceRef": {},
                                "f:recorderRef": {},
                                "f:streamNumber": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "subresource": "status",
                        "time": "2023-06-12T12:10:09Z"
                    }
                ],
                "name": "8309eeb7-66b3-409f-9cf7-4e80347a7c7f",
                "namespace": "test-organization",
                "resourceVersion": "702144",
                "uid": "f5fa0fc6-ab32-460e-9435-7799b853b687"
            },
            "spec": {
                "bitrate": 0,
                "bitrateMode": "CBR",
                "compression": "Undefined",
                "description": "Video stream 2 description",
                "displayName": "Video stream 2",
                "enabled": true,
                "extraParameters": {
                    "apiVersion": "v1alpha1",
                    "kind": "extraParameters",
                    "spec": {}
                },
                "framerate": 30,
                "isSlowFramerateUsed": false,
                "passiveMode": false,
                "quality": 60,
                "resolution": {
                    "height": 1080,
                    "width": 1920
                },
                "slowFramerate": 5,
                "transport": "Automatic",
                "useForRecording": false
            },
            "status": {
                "capabilities": {
                    "compressions": [],
                    "internalStates": "",
                    "isRecordingSupported": false,
                    "isSlowFramerateSupported": false,
                    "transports": []
                },
                "channelNumber": 2,
                "channelRef": "9c143ed6-5855-4a5f-9984-a55b7f49735d",
                "conditions": [
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Added"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Connected"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "CapabilitiesReceived"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Configured"
                    }
                ],
                "deviceRef": "bac6dd82-999e-4876-9f81-f00568efdffb",
                "recorderRef": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                "streamNumber": 0
            }
        },
        {
            "apiVersion": "recorder.mirasys.com/v1alpha1",
            "kind": "VideoStream",
            "metadata": {
                "annotations": {
                    "kubectl.kubernetes.io/last-applied-configuration": "{\\"apiVersion\\":\\"recorder.mirasys.com/v1alpha1\\",\\"kind\\":\\"VideoStream\\",\\"metadata\\":{\\"annotations\\":{},\\"labels\\":{\\"dev\\":\\"bac6dd82-999e-4876-9f81-f00568efdffb\\",\\"rec\\":\\"a87df1cb-3062-4abc-a55e-4c6d5b421548\\",\\"vch\\":\\"9c143ed6-5855-4a5f-9984-a55b7f49735d\\"},\\"name\\":\\"f33efb80-f7a2-4d03-90d6-ab5ed63b261d\\",\\"namespace\\":\\"test-organization\\"},\\"spec\\":{\\"description\\":\\"Video stream 1 description\\",\\"displayName\\":\\"Video stream 1\\",\\"enabled\\":true}}\\n"
                },
                "creationTimestamp": "2023-06-02T13:51:52Z",
                "generation": 7,
                "labels": {
                    "dev": "bac6dd82-999e-4876-9f81-f00568efdffb",
                    "rec": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                    "vch": "9c143ed6-5855-4a5f-9984-a55b7f49735d"
                },
                "managedFields": [
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:status": {
                                ".": {},
                                "f:capabilities": {
                                    ".": {},
                                    "f:compressions": {},
                                    "f:internalStates": {},
                                    "f:isRecordingSupported": {},
                                    "f:isSlowFramerateSupported": {},
                                    "f:transports": {}
                                },
                                "f:channelNumber": {},
                                "f:channelRef": {},
                                "f:conditions": {},
                                "f:deviceRef": {},
                                "f:recorderRef": {},
                                "f:streamNumber": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "subresource": "status",
                        "time": "2023-06-09T07:04:40Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:metadata": {
                                "f:annotations": {
                                    ".": {},
                                    "f:kubectl.kubernetes.io/last-applied-configuration": {}
                                },
                                "f:labels": {
                                    ".": {},
                                    "f:dev": {},
                                    "f:rec": {},
                                    "f:vch": {}
                                }
                            },
                            "f:spec": {
                                ".": {},
                                "f:description": {},
                                "f:displayName": {},
                                "f:enabled": {}
                            }
                        },
                        "manager": "kubectl-client-side-apply",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:02Z"
                    },
                    {
                        "apiVersion": "recorder.mirasys.com/v1alpha1",
                        "fieldsType": "FieldsV1",
                        "fieldsV1": {
                            "f:metadata": {
                                "f:ownerReferences": {
                                    ".": {},
                                    "k:{\\"uid\\":\\"7ef856fe-b0ab-4ba0-9745-80b48a8328ce\\"}": {}
                                }
                            },
                            "f:spec": {
                                "f:bitrate": {},
                                "f:bitrateMode": {},
                                "f:compression": {},
                                "f:extraParameters": {
                                    ".": {},
                                    "f:apiVersion": {},
                                    "f:kind": {},
                                    "f:spec": {}
                                },
                                "f:framerate": {},
                                "f:isSlowFramerateUsed": {},
                                "f:passiveMode": {},
                                "f:quality": {},
                                "f:resolution": {
                                    ".": {},
                                    "f:height": {},
                                    "f:width": {}
                                },
                                "f:slowFramerate": {},
                                "f:transport": {},
                                "f:useForRecording": {}
                            }
                        },
                        "manager": "unknown",
                        "operation": "Update",
                        "time": "2023-06-12T12:09:30Z"
                    }
                ],
                "name": "f33efb80-f7a2-4d03-90d6-ab5ed63b261d",
                "namespace": "test-organization",
                "ownerReferences": [
                    {
                        "apiVersion": "v1alpha1",
                        "kind": "VideoChannel",
                        "name": "9c143ed6-5855-4a5f-9984-a55b7f49735d",
                        "uid": "7ef856fe-b0ab-4ba0-9745-80b48a8328ce"
                    }
                ],
                "resourceVersion": "702102",
                "uid": "e9ca6cb1-9838-4def-8251-e55ed1aa0f8f"
            },
            "spec": {
                "bitrate": 0,
                "bitrateMode": "CBR",
                "compression": "Undefined",
                "description": "Video stream 1 description",
                "displayName": "Video stream 1",
                "enabled": true,
                "extraParameters": {
                    "apiVersion": "v1alpha1",
                    "kind": "extraParameters",
                    "spec": {}
                },
                "framerate": 30,
                "isSlowFramerateUsed": false,
                "passiveMode": false,
                "quality": 60,
                "resolution": {
                    "height": 1080,
                    "width": 1920
                },
                "slowFramerate": 5,
                "transport": "Automatic",
                "useForRecording": false
            },
            "status": {
                "capabilities": {
                    "compressions": [],
                    "internalStates": "",
                    "isRecordingSupported": false,
                    "isSlowFramerateSupported": false,
                    "transports": []
                },
                "channelNumber": 1,
                "channelRef": "9c143ed6-5855-4a5f-9984-a55b7f49735d",
                "conditions": [
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Added"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Connected"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "CapabilitiesReceived"
                    },
                    {
                        "message": "",
                        "observedGeneration": 0,
                        "reason": "Unknown",
                        "status": "Unknown",
                        "type": "Configured"
                    }
                ],
                "deviceRef": "bac6dd82-999e-4876-9f81-f00568efdffb",
                "recorderRef": "a87df1cb-3062-4abc-a55e-4c6d5b421548",
                "streamNumber": 0
            }
        }
    ],
    "kind": "VideoStreamList",
    "metadata": {
        "continue": "",
        "resourceVersion": "862808"
    }
}`)
