const findLine = (buffer: string, fn: (s: string) => void): string => {
  const newLineIndex = buffer.indexOf('\n')
  // if the buffer doesn't contain a new line, do nothing
  if (newLineIndex === -1) {
    return buffer
  }
  const chunk = buffer.slice(0, buffer.indexOf('\n'))
  const newBuffer = buffer.slice(buffer.indexOf('\n') + 1)

  // found a new line! execute the callback
  fn(chunk)

  // there could be more lines, checking again
  return findLine(newBuffer, fn)
}

export enum WatchEventType {
  ADDED,
  MODIFIED,
  DELETED,
  BOOKMARK,
}

export const watch = <T>(
  serverURL: string,
  token: string,
  resourceVersion: string,
  name: string,
  callback: (type: WatchEventType, object: T) => void
) => {
  // TODO: make watcher class which has server URL pre-configured
  // TODO: add namespace
  // TODO: handle disconnection
  // TODO: error handling
  let url = serverURL + `?watch=1`

  if (resourceVersion) {
    url += `&resourceVersion=` + resourceVersion
  }
  if (name) {
    url += `&fieldSelector=metadata.name==` + name
  }

  fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.body !== null) {
      // ref: https://learnk8s.io/real-time-dashboard
      const stream = response.body.getReader()
      const utf8Decoder = new TextDecoder('utf-8')
      let buffer = ''

      // wait for an update and prepare to read it
      return stream.read().then(({ done, value }): Promise<void> | void => {
        if (done) {
          console.log('Watch request terminated')
          return
        }
        buffer += utf8Decoder.decode(value)
        const remainingBuffer = findLine(buffer, (line) => {
          try {
            const event = JSON.parse(line)
            console.log('Watch event: ', event.type, event.object)
            // TODO: check that validated type and objects are of right structure
            // TODO: to do handle duplicate event
            callback(event.type, event.object)
          } catch (error) {
            console.log('Error while parsing', line, '\n', error)
          }
        })

        buffer = remainingBuffer

        // continue waiting & reading the stream of updates from the server
        return stream.read().then()
      })
    }
  })
}

export const exportForTesting = {
  findLine,
}
