import { SocketData } from 'components/RightSidebar'

export const SortAlarms = (
  a: Pick<SocketData, 'created_at'>,
  b: Pick<SocketData, 'created_at'>
) => {
  const cDate = new Date(b.created_at).getTime()
  const cDate1 = new Date(a.created_at).getTime()

  return cDate > cDate1 ? 1 : -1
}
