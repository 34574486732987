import { useEffect, useState } from 'react'
import { TabType } from 'store/slices/tab'
import * as Client from 'client'
import { ComMirasysUiV1alpha1Tab as Tab } from 'client/api'
// import { newTab } from './types'
import { useMap } from 'usehooks-ts'
import { watch, WatchEventType } from 'common-lib/services/watch'

interface UIState {
  profilePin: boolean
  alarmPin: boolean
  theme: string
  tabs: Array<TabType>
}

const useFetchTab = (): Array<Tab> => {
  const [tabs, actions] = useMap<string, Tab>(new Map())
  const [resourceVersion, setRV] = useState('')
  const serverAdress = 'https://kubernetes.webtest.mirasys.com'
  const cfg = new Client.Configuration({
    basePath: serverAdress,
  })
  const c = new Client.UiMirasysComV1alpha1Api(cfg)

  const fetchData = async () => {
    const list = await c.listUiMirasysComV1alpha1TabForAllNamespaces()
    if (list.status !== 200) {
      throw new Error('Data could not be fetched!')
    } else {
      return list.data
    }
  }

  useEffect(() => {
    fetchData()
      .then((list) => {
        const tabMap = new Map<string, Tab>()
        list.items.forEach((item) => {
          if (item.metadata?.name !== undefined) {
            const name = item.metadata?.name
            tabMap.set(name, item)
          }
        })
        actions.setAll(tabMap)
        if (list.metadata?.resourceVersion !== undefined) {
          setRV(list.metadata?.resourceVersion)
        }
      })
      .catch((e) => e.message)
  }, [])

  // const [newName, setNewName] = useState('')
  //
  // const createTab = async () => {
  //   const name = newName
  //   const namespace = 'profile-x'
  //   const t = newTab(name, namespace)
  //   t.name = newName
  //   t.type = 'View'
  //   const t2 = await c.createUiMirasysComV1alpha1NamespacedTab(
  //     namespace,
  //     undefined,
  //     undefined,
  //     undefined,
  //     undefined,
  //     t
  //   )
  //   actions.set(name, t2.data)
  // }

  const watchData = async () => {
    if (resourceVersion === '') {
      return
    }

    watch(
      serverAdress,
      'tabs',
      resourceVersion,
      'tabs',
      (type: WatchEventType, tab: Tab) => {
        const name = tab.metadata?.name
        if (name !== undefined) {
          actions.set(name, tab)
        }
      }
    )
  }

  useEffect(() => {
    watchData()
  }, [resourceVersion])

  return Array.from(tabs.values())
}

const fetchUIState = (): UIState => {
  const defaultTabs = [
    // type of tabs: view, tool, setting, newTabDashBoard
    {
      name: 'View 1',
      type: 'view',
      view: {
        layout: {
          rows: 1,
          columns: 1,
        },
        streamSrc: [1],
      },
      bookmarkColor: '#CDBC4E',
    },
    // {
    //   id: 3,
    //   name: 'Tool 1',
    //   type: 'tool',
    //   tool: {
    //     toolType: 'search',
    //     search: {
    //       searchType: 'color',
    //       searchClass: 'person',
    //       top: 'white',
    //       bottom: 'black',
    //       cameras: [1, 2, 3],
    //       timeStart: Date(),
    //       timeEnd: Date(),
    //     },
    //   },
    //   bookmarkColor: '#CD794E',
    // },
    // {
    //   id: 4,
    //   name: 'Tool 2',
    //   type: 'new',
    //   tool: {
    //     toolType: 'search',
    //     search: {
    //       searchType: 'color',
    //       searchClass: 'person',
    //       top: 'white',
    //       bottom: 'black',
    //       cameras: [1, 2, 3],
    //       timeStart: Date(),
    //       timeEnd: Date(),
    //     },
    //   },
    // },
  ]
  /**
   * @description temp solution with local storage, will be removed once the API is ready
   */
  const savedTabs = localStorage.getItem('SAVED_TABS')
  const tabs = savedTabs ? JSON.parse(savedTabs) : defaultTabs

  return {
    profilePin: true,
    alarmPin: true,
    theme: 'dark',
    tabs,
  }
}

export { fetchUIState, useFetchTab }
