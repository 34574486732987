import { ReactNode, useRef } from 'react'
import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack'
import { IconButton } from '@mui/material'
import { CheckCircle, Close, Info, WarningRounded } from '@mui/icons-material'

type Props = {
  children: ReactNode
}

export const SnackbarProvider = ({ children }: Props) => {
  const notistackRef = useRef<any>(null)

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <NotistackProvider
      ref={notistackRef}
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={{
        info: <Info />,
        success: <CheckCircle />,
        warning: <WarningRounded />,
        error: <Info />,
      }}
      action={(key) => (
        <IconButton size="small" onClick={onClose(key)}>
          <Close />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  )
}
