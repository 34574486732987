import Cookies from 'js-cookie'
import { MIRASYS_BASE_DOMAIN } from '../constants/endpoints'

export enum COOKIE_ITEM {
  ACCESS_TOKEN = 'mira-access-token',
  REFRESH_TOKEN = 'mira-refresh-token',
  ACCESS_EXPIRES_IN = 'mira-access-expires-in',
  REFRESH_EXPIRES_IN = 'mira-refresh-expires-in',
}

export class CookieService {
  static get(name: COOKIE_ITEM) {
    return Cookies.get(name)
  }

  static set(name: COOKIE_ITEM, value: string, remember?: boolean) {
    Cookies.set(name, value, {
      domain: MIRASYS_BASE_DOMAIN,
      path: '/',
      expires: remember ? 7 : undefined,
    })
  }

  static remove(name: COOKIE_ITEM) {
    Cookies.remove(name, {
      domain: MIRASYS_BASE_DOMAIN,
      path: '/',
    })
  }
}
