import { FC, ReactNode } from 'react'
import AuthService from '../../services/auth'

export interface ILogoutButtonProps {
  className?: string
  children: ReactNode
}

export const LogoutButton: FC<ILogoutButtonProps> = ({
  className,
  children,
}) => {
  const onLogout = () => {
    AuthService.logout()
  }

  return (
    <div
      className={className}
      id="logout_button"
      data-testid="logout-btn"
      onClick={onLogout}
    >
      {children}
    </div>
  )
}
