import React from 'react'
import LogoIcon from 'assets/icons/logo.svg'
import ProfileTree, { IProfileTreeNode } from './ProfileTree'
import { useDispatch } from 'react-redux'
import { openCamera, ViewTab } from 'store/slices/tab'
import { useTypedSelector } from 'common-lib/hooks/useTypedStore'

export const profiles: IProfileTreeNode[] = [
  {
    IsEnabled: true,
    Name: 'Service',
    ProfileNodes: [
      {
        Name: 'Cameras',
        ProfileNodes: [
          {
            Name: 'Hall of Fame',
            Camera: {
              Guid: '00000001-0000-0000-0000-000000000000',
              IsEnabled: true,
              Name: 'Hall of Fame',
              Channel: 1,
              Mode: 'Live',
            },
          },
          {
            Name: 'Camera room 1',
            Camera: {
              Guid: '00000002-0000-0000-0000-000000000000',
              IsEnabled: true,
              Name: 'Camera room 1',
              Channel: 2,
              Mode: 'Playback',
            },
          },
          {
            Name: 'PTZ camera',
            Camera: {
              Guid: '00000003-0000-0000-0000-000000000000',
              IsEnabled: true,
              Name: 'PTZ camera',
              Channel: 3,
              Mode: 'Live',
            },
          },
          {
            Name: 'Camera room 2',
            Camera: {
              Guid: '00000004-0000-0000-0000-000000000000',
              IsEnabled: true,
              Name: 'Camera room 2',
              Channel: 4,
              Mode: 'Live',
            },
          },
        ],
      },
      {
        Name: 'Digital inputs',
        ProfileNodes: [],
      },
      {
        Name: 'Digital outputs',
        ProfileNodes: [],
      },
      {
        Name: 'Video outputs',
        ProfileNodes: [],
      },
      {
        Name: 'Audio channels',
        ProfileNodes: [],
      },
      {
        Name: 'Text channels',
        ProfileNodes: [],
      },
      {
        Name: 'Audio communication',
        ProfileNodes: [],
      },
    ],
  },
]

const LeftSidebar = () => {
  const activeTabView = useTypedSelector(
    ({ tab: { tabs, activeTabIdx } }) => (tabs[activeTabIdx] as ViewTab)?.view
  )
  const dispatch = useDispatch()

  let channelIds: number[] = []
  // only works if the current tab is a view tab
  if (activeTabView) {
    channelIds = activeTabView.streamSrc.flat()
  }

  const onClickNode = (item: IProfileTreeNode) => {
    dispatch(openCamera(item.Camera?.Channel))
  }

  return (
    <div className="flex h-screen w-48 flex-col bg-black">
      <img
        className="mx-auto my-3 flex w-30 max-w-[95%] justify-center"
        src={LogoIcon}
        alt="MIRASYS"
        data-testid="company-logo"
      />
      <div className="mr-0.5 h-0 flex-grow overflow-auto bg-primary-100 pt-4">
        {profiles.map((item, i) => (
          <ProfileTree
            key={i}
            data={item}
            level={0}
            onClickNode={onClickNode}
            channelIds={channelIds}
          />
        ))}
      </div>
    </div>
  )
}

export default LeftSidebar
