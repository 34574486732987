import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  CaretDownIcon,
  CirclesIcon,
  CloseCircleIcon,
  CloseIcon,
  CloseSquareIcon,
  EditIcon,
} from 'assets/icons'
import {
  changeName,
  changeTabColor,
  closeAllCameras,
  closeTab,
  switchTab,
  TabType,
} from 'store/slices/tab'
import classNames from 'classnames'
import { useBoolean } from 'usehooks-ts'
import { Popover } from 'react-tiny-popover'
import ContextMenu from 'components/ContextMenu'
import { showInputAlert } from 'utils/helpers/alerts'
import ColorPalette from '../../ColorPalette'

interface Props {
  idx: number
  tab: TabType
  activeTabIdx: number
  first?: boolean
  last?: boolean
}

const Tab = ({ idx, tab, activeTabIdx, first, last }: Props) => {
  const dispatch = useDispatch()
  const tabRef = useRef(null)
  const {
    value: menuVisible,
    setTrue: openMenu,
    setFalse: closeMenu,
  } = useBoolean(false)
  const {
    value: colorPaletteVisible,
    setTrue: openColorPalette,
    setFalse: closeColorPalette,
  } = useBoolean(false)
  const classes = classNames('flex justify-between gap-2 px-2', {
    'bg-primary': idx === activeTabIdx,
    'bg-primary-50': idx !== activeTabIdx,
    'rounded-tl-lg': first,
    'rounded-tr-lg': last,
    'hover:cursor-pointer hover:bg-primary': !tab.disabled,
    'hover:cursor-not-allowed': tab.disabled,
  })

  const handleClick = () => {
    if (!tab.disabled) {
      dispatch(switchTab(idx))
    }
  }

  const handleClose = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()
    dispatch(closeTab(idx))
  }

  const handleRightClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    closeColorPalette()
    openMenu()
  }

  const renderHeadPart = () => {
    if (!tab.bookmarkColor && tab.disableFlag) return
    return (
      <div className="flex h-full">
        {tab.bookmarkColor && (
          <div
            className="flex w-2"
            style={{ backgroundColor: tab.bookmarkColor }}
          />
        )}
        {!tab.disableFlag && (
          <CaretDownIcon className="relative top-[-10px] fill-grey" />
        )}
      </div>
    )
  }

  const handleRename = async () => {
    const result = await showInputAlert(`Rename tab "${tab.name}" to: `)
    if (result.isConfirmed && result.value) {
      dispatch(
        changeName({
          id: idx,
          name: result.value,
        })
      )
    }
  }

  const handleCloseAllStreams = () => {
    dispatch(closeAllCameras())
  }

  const renderContextMenu = () => {
    if (tab.type !== 'view') return <i />
    return (
      <ContextMenu
        onClose={closeMenu}
        items={[
          { label: 'Rename', icon: EditIcon, onClick: handleRename },
          {
            label: 'Color tag',
            icon: CirclesIcon,
            onClick: () => setTimeout(openColorPalette, 0),
          },
          {
            label: 'Close all streams',
            icon: CloseSquareIcon,
            onClick: handleCloseAllStreams,
          },
          {
            label: 'Close this view',
            icon: CloseCircleIcon,
            onClick: handleClose,
          },
        ]}
      />
    )
  }

  const handleChangeTabColor = (color: string | null) => {
    dispatch(changeTabColor({ id: idx, color }))
    closeColorPalette()
  }

  const renderColorPalette = () => (
    <ColorPalette onSelect={handleChangeTabColor} />
  )

  const handleClickOutside = () => {
    closeMenu()
    closeColorPalette()
  }

  return (
    <Popover
      isOpen={menuVisible || colorPaletteVisible}
      content={colorPaletteVisible ? renderColorPalette() : renderContextMenu()}
      onClickOutside={handleClickOutside}
      positions={['bottom']}
    >
      <div
        ref={tabRef}
        onClick={handleClick}
        onContextMenu={handleRightClick}
        className={classes}
        data-testid="tab-container"
      >
        {renderHeadPart()}
        <div className="flex h-full items-center gap-1">
          {tab.icon}
          {tab.name && (
            <p className="whitespace-pre text-xs text-white">{tab.name}</p>
          )}
        </div>
        {!tab.pinned && (
          <button
            className="hover:drop-shadow-primary flex items-center p-1 hover:scale-110"
            onClick={handleClose}
            data-testid="close-btn"
          >
            <CloseIcon className="w-2 fill-white" />
          </button>
        )}
      </div>
    </Popover>
  )
}

export default Tab
