import {
  setStreamOpenTime,
  setStreamStatus,
  StreamStatus,
} from 'store/slices/channel'
import {
  useTypedDispatch,
  useTypedSelector,
} from 'common-lib/hooks/useTypedStore'

/**
 * @description The custom hooks for controlling a selected video stream:
 * - changing status (live, playback, paused)
 * - jumping to a specific point of time with adapted status
 */
export const useVideoControls = () => {
  const selectedChannelId = useTypedSelector(
    ({ channel }) => channel.selectedChannelId
  )
  const status = useTypedSelector(
    ({ channel }) =>
      channel.timers[channel.selectedChannelId]?.status ?? StreamStatus.Live
  )
  const dispatch = useTypedDispatch()
  const isLive = selectedChannelId !== -1 && status === StreamStatus.Live
  const isPaused = status === StreamStatus.Paused
  const isPlayback = status === StreamStatus.Playback

  const setLive = () => {
    if (status === StreamStatus.Live) return
    const now = new Date()
    setStatus(StreamStatus.Live)
    dispatch(
      setStreamOpenTime({
        channelId: selectedChannelId,
        streamOpenTime: now.getTime(),
      })
    )
  }

  const setPlayback = (timeInMs: number) => {
    dispatch(
      setStreamOpenTime({
        channelId: selectedChannelId,
        streamOpenTime: timeInMs,
      })
    )
    setStatus(StreamStatus.Playback)
  }

  const jumpToTime = (timeInMs: number) => {
    const now = new Date().getTime()
    if (now < timeInMs) {
      setLive()
    } else {
      setPlayback(timeInMs)
    }
  }

  const setPaused = (paused?: boolean) => {
    setStatus(paused ? StreamStatus.Paused : StreamStatus.Playback)
  }

  const setStatus = (status: StreamStatus) => {
    dispatch(
      setStreamStatus({
        channelId: selectedChannelId,
        status,
      })
    )
  }

  return {
    jumpToTime,
    setLive,
    setPlayback,
    setStatus,
    setPaused,
    isLive,
    isPaused,
    isPlayback,
  }
}
